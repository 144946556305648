
import {rzContainer, rzDottedLoader} from 'razlet-ui';

export default {
  components: {
    rzContainer, 
    rzDottedLoader,
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.$store.commit('setMobile', window.innerWidth <= 992);
    });
  },
};
