import { render, staticRenderFns } from "./default.vue?vue&type=template&id=7f3c18ec&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=7f3c18ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f3c18ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainHeader: require('/opt/kras.aero/src/components/main-header.vue').default,MainFooter: require('/opt/kras.aero/src/components/main-footer.vue').default})
